import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import "./index.scss";
import "./assets/css/main.scss";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import {BrowserRouter} from 'react-router-dom';




const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

            <App />
    
   
      </PersistGate>
    </Provider>
  </React.StrictMode>
  
);


